// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    app: `${ROOTS.DASHBOARD}/app`,
    product: `${ROOTS.DASHBOARD}/product`,
    product_mapping : `${ROOTS.DASHBOARD}/product/mapping`,
    product_mapping_edit: (upsteer_id: string) => `${ROOTS.DASHBOARD}/product/mapping/${upsteer_id}/edit`,
    product_mapping_new: `${ROOTS.DASHBOARD}/product/mapping/new`,
    product_details: (sku: string) => `${ROOTS.DASHBOARD}/replenishment/details/${sku}`,
    invoice: `${ROOTS.DASHBOARD}/invoice`,
    invoice_created: `${ROOTS.DASHBOARD}/invoice/created`,
    all_invoice_details: (id: string) => `${ROOTS.DASHBOARD}/invoice/all-details/${id}`,
    created_invoice_details: (id: string) => `${ROOTS.DASHBOARD}/invoice/created-details/${id}`,
    draft_invoice_details: (id: string) => `${ROOTS.DASHBOARD}/invoice/draft-details/${id}`,
    order: `${ROOTS.DASHBOARD}/order`,
    order_new: `${ROOTS.DASHBOARD}/order/new`,
    order_details: (id: string, type: string) => `${ROOTS.DASHBOARD}/order/${id}/${type}`,
    order_edit: (id: string) => `${ROOTS.DASHBOARD}/order/${id}/edit`,
    order_upsteer: `${ROOTS.DASHBOARD}/order/upsteer-list`,
    connection: `${ROOTS.DASHBOARD}/connection`,
    connection_new: `${ROOTS.DASHBOARD}/connection/new`,
    connection_callback: `${ROOTS.DASHBOARD}/connection/callback`,
    two: `${ROOTS.DASHBOARD}/two`,
    analytics: `${ROOTS.DASHBOARD}/analytics`,
    general:{
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
    },
    account: `${ROOTS.DASHBOARD}/account`,
    customer: {
      root: `${ROOTS.DASHBOARD}/customer`,
      new: `${ROOTS.DASHBOARD}/customer/new`,
      list: `${ROOTS.DASHBOARD}/customer/list`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/customer/${id}/edit`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/1/edit`,
      },
    },
  },
};
